<div class="container text-center mt-5">

    <div class="row justify-content-center text-center">
        <div class="col-sm-8 mb-5">
            <h1 class="dillan">Açores</h1>
            <hr class="py-2"/>
            <p><i>Out 2024 // São Miguel</i></p>

            <p>
            </p>
           

        </div>
    </div>

</div>


<div class="container">

    <div class="travel" data-aos="fade-up" data-aos-duration="1000">

       
        <div class="row">
            <div class="col-lg-7">
                <img src="assets/img/travels/2024/acores/acores.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-5">
                <img src="assets/img/travels/2024/acores/acores18.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row vertical-photos">
            <div class="col-lg-5">
                <img src="assets/img/travels/2024/acores/acores23.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-7">
                <img src="assets/img/travels/2024/acores/acores24.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4">
                <img src="assets/img/travels/2024/acores/acores22.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2024/acores/acores20.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2024/acores/acores17.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/travels/2024/acores/acores21.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/travels/2024/acores/acores14.jpg" alt="" class="img-fluid" />
            </div>

          
        </div>

        <div class="row vertical-photos">
            <div class="col-lg-12">
                <img src="assets/img/travels/2024/acores/acores12.jpg" alt="" class="img-fluid" />
            </div>
          
        </div>

        <div class="row">
            <div class="col-lg-4">
                <img src="assets/img/travels/2024/acores/acores19.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2024/acores/acores16.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2024/acores/acores15.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row vertical-photos">
            <div class="col-lg-6">
                <img src="assets/img/travels/2024/acores/acores13.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/travels/2024/acores/acores10.jpg" alt="" class="img-fluid" />
            </div>

          
        </div>

        <div class="row">
            <div class="col-lg-5">
                <img src="assets/img/travels/2024/acores/acores8.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-7">
                <img src="assets/img/travels/2024/acores/acores9.jpg" alt="" class="img-fluid" />
            </div>

          
        </div>

        <div class="row">
            <div class="col-lg-4">
                <img src="assets/img/travels/2024/acores/acores7.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2024/acores/acores6.jpg" alt="" class="img-fluid" />
            </div>


            <div class="col-lg-4">
                <img src="assets/img/travels/2024/acores/acores5.jpg" alt="" class="img-fluid" />
            </div>

          
        </div>

        <div class="row">
            <div class="col-lg-7">
                <img src="assets/img/travels/2024/acores/acores2.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-5">
                <img src="assets/img/travels/2024/acores/acores3.jpg" alt="" class="img-fluid" />
            </div>


          

          
        </div>

    </div>

</div>