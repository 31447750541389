<div class="container text-center mt-5">

    <div class="row justify-content-center text-center">
        <div class="col-sm-8 mb-5">
            <h1 class="dillan">Casamentos</h1>
        </div>
    </div>

</div>

<div class="container text-center">

    <div class="travel">

        <div class="row d-flex justify-content-center">
            <div class="col-sm-10">
                <div class="ratio ratio-16x9 mb-3">
                    <iframe class="border-radius" src="https://player.vimeo.com/video/1033951557" title="YouTube video" allowfullscreen></iframe>
                </div>
            </div>
        </div>

        <p class="mb-5"><i><b>Diana & Vítor</b> <br/>Videógrafo principal <br/>Edição: Nelson Sousa <br/> Casamento: Cátia Soares Photography</i></p>

        <div class="row d-flex justify-content-center">
            <div class="col-sm-10">
                <div class="ratio ratio-16x9 mb-3">
                    <iframe class="border-radius" src="https://player.vimeo.com/video/1034553132" title="YouTube video"allowfullscreen></iframe>
                </div>
            </div>
        </div>

        <p class="mb-5"><i><b>Érica e André</b></i> <br/>Videógrafo secundário <br/>Edição: <a target="_blank" href="https://www.brasdio.com">Diogo Bras</a> <br/> Casamento: Cátia Soares Photography</p>


    </div>
</div>