<div class="container mt-5" data-aos="fade-up">

    <div class="text-center p-4">
        <h1 class="dillan">{{ selectedWork.title }}</h1>
    </div>
   

    <div class="adventures">

        <div *ngFor="let video of selectedWork.videoUrl" class="row justify-content-center">

            <div class="col-sm-9 text-center">

                <div class="ratio ratio-16x9 mb-3">
                    <iframe class="border-radius" [src]="video | safe" title="YouTube video" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-5">
        <div *ngFor="let item of selectedWork.frames" class="col-lg-6 thumb" data-aos="fade-up" data-aos-duration="500">
            <img [src]="'assets/img/works/'  +  selectedWork.name + '/' + item" alt="" class="img-fluid" />
        </div>
    </div>

    <div *ngIf="selectedWork.name == 'zino-palace' " class="travel">

        <div class="row">
            <div class="col-lg-12">
                <img src="assets/img/works/zino-palace/zino-14.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-3">
                <img src="assets/img/works/zino-palace/zino-4.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6 ">
                <img src="assets/img/works/zino-palace/zino-5.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-3">
                <img src="assets/img/works/zino-palace/zino-6.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 ">
                <img src="assets/img/works/zino-palace/zino-2.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/works/zino-palace/zino-3.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12 ">
                <img src="assets/img/works/zino-palace/zino.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4">
                <img src="assets/img/works/zino-palace/zino-7.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-5 ">
                <img src="assets/img/works/zino-palace/zino-8.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-3">
                <img src="assets/img/works/zino-palace/zino-9.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/works/zino-palace/zino-10.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6 ">
                <img src="assets/img/works/zino-palace/zino-11.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <img src="assets/img/works/zino-palace/zino-12.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row vertical-photos">
            <div class="col-lg-6">
                <img src="assets/img/works/zino-palace/zino-13.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/works/zino-palace/zino-16.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row vertical-photos">
            <div class="col-lg-12 ">
                <img src="assets/img/works/zino-palace/zino-15.jpg" alt="" class="img-fluid" />
            </div>
        </div>
    </div>

    <div *ngIf="selectedWork.name == 'casa-das-flores' " class="travel">


        <div class="text-center my-5">
            <h1 class="dillan">{{ selectedWork.title }}</h1>
        </div>

        <div class="row vertical-photos">
            <div class="col-lg-12">
                <img src="assets/img/works/casa-das-flores/casa-das-flores-3.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/works/casa-das-flores/casa-das-flores.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/works/casa-das-flores/casa-das-flores-12.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">

            <div class="col-lg-6">
                <img src="assets/img/works/casa-das-flores/casa-das-flores-6.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/works/casa-das-flores/casa-das-flores-5.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/works/casa-das-flores/casa-das-flores-7.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/works/casa-das-flores/casa-das-flores-8.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">

            <div class="col-lg-6">
                <img src="assets/img/works/casa-das-flores/casa-das-flores-9.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/works/casa-das-flores/casa-das-flores-2.jpg" alt="" class="img-fluid" />
            </div>
        </div>





        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/works/casa-das-flores/casa-das-flores-11.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/works/casa-das-flores/casa-das-flores-10.jpg" alt="" class="img-fluid" />
            </div>
        </div>

    </div>

    <div *ngIf="selectedWork.name == 'raposa-lodges' " class="travel">

        <div class="row vertical-photos">
            <div class="col-lg-12">
                <img src="assets/img/works/raposa-lodges/raposa-lodges-22.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/works/raposa-lodges/raposa-lodges-4.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/works/raposa-lodges/raposa-lodges-10.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row">
            <div class="col-lg-5">
                <img src="assets/img/works/raposa-lodges/raposa-lodges-2.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-7">
                <img src="assets/img/works/raposa-lodges/raposa-lodges-5.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row ">
            <div class="col-lg-8">
                <img src="assets/img/works/raposa-lodges/raposa-lodges-19.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-4">
                <img src="assets/img/works/raposa-lodges/raposa-lodges-23.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row vertical-photos">
            <div class="col-lg-6">
                <img src="assets/img/works/raposa-lodges/raposa-lodges-12.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/works/raposa-lodges/raposa-lodges-17.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row vertical-photos">
            <div class="col-lg-7">
                <img src="assets/img/works/raposa-lodges/raposa-lodges-8.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-5">
                <img src="assets/img/works/raposa-lodges/raposa-lodges-13.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row vertical-photos">
            <div class="col-lg-6">
                <img src="assets/img/works/raposa-lodges/raposa-lodges-7.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/works/raposa-lodges/raposa-lodges-9.jpg" alt="" class="img-fluid" />
            </div>
        </div>



        <div class="row vertical-photos">
            <div class="col-lg-12">
                <img src="assets/img/works/raposa-lodges/raposa-lodges-20.jpg" alt="" class="img-fluid" />
            </div>
        </div>




    </div>

    <div *ngIf="selectedWork.name == 'refugio-carrascal' " class="travel">

        <div class="row">
            <div class="col-lg-4">
                <img src="assets/img/works/refugio-carrascal/carrascal-6.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-4">
                <img src="assets/img/works/refugio-carrascal/carrascal-7.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-4">
                <img src="assets/img/works/refugio-carrascal/carrascal-8.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-7">
                <img src="assets/img/works/refugio-carrascal/carrascal-13.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-5">
                <img src="assets/img/works/refugio-carrascal/carrascal-14.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-7">
                <img src="assets/img/works/refugio-carrascal/carrascal-11.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-5">
                <img src="assets/img/works/refugio-carrascal/carrascal-5.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-4">
                <img src="assets/img/works/refugio-carrascal/carrascal-2.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-8">
                <img src="assets/img/works/refugio-carrascal/carrascal.jpg" alt="" class="img-fluid" />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/works/refugio-carrascal/carrascal-3.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/works/refugio-carrascal/carrascal-10.jpg" alt="" class="img-fluid" />
            </div>
        </div>


        <div class="row">
            <div class="col-lg-4">
                <img src="assets/img/works/refugio-carrascal/carrascal-15.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-4">
                <img src="assets/img/works/refugio-carrascal/carrascal-16.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-4">
                <img src="assets/img/works/refugio-carrascal/carrascal-17.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/works/refugio-carrascal/carrascal-18.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/works/refugio-carrascal/carrascal-19.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row">
            <div class="col-lg-9">
                <img src="assets/img/works/refugio-carrascal/carrascal-20.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-3">
                <img src="assets/img/works/refugio-carrascal/carrascal-21.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row">
            <div class="col-lg-7">
                <img src="assets/img/works/refugio-carrascal/carrascal-22.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-5">
                <img src="assets/img/works/refugio-carrascal/carrascal-23.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row vertical-photos">
            <div class="col-lg-6">
                <img src="assets/img/works/refugio-carrascal/carrascal-24.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/works/refugio-carrascal/carrascal-25.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row ">
            <div class="col-lg-4">
                <img src="assets/img/works/refugio-carrascal/carrascal-27.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-8">
                <img src="assets/img/works/refugio-carrascal/carrascal-26.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row ">
            <div class="col-lg-8">
                <img src="assets/img/works/refugio-carrascal/carrascal-30.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-4">
                <img src="assets/img/works/refugio-carrascal/carrascal-31.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row vertical-photos">
            <div class="col-lg-12">
                <img src="assets/img/works/refugio-carrascal/carrascal-29.jpg" alt="" class="img-fluid" />
            </div>


        </div>




    </div>

    <div *ngIf="selectedWork.name == 'refugio-das-oliveiras' " class="travel">

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-9.jpg" alt=""
                    class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-10.jpg" alt=""
                    class="img-fluid" />
            </div>

        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-6.jpg" alt=""
                    class="img-fluid" />
            </div>

        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-3.jpg" alt=""
                    class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-2.jpg" alt=""
                    class="img-fluid" />
            </div>

        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-4.jpg" alt=""
                    class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-25.jpg" alt=""
                    class="img-fluid" />
            </div>

        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-7.jpg" alt=""
                    class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-8.jpg" alt=""
                    class="img-fluid" />
            </div>

        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-17.jpg" alt=""
                    class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-16.jpg" alt=""
                    class="img-fluid" />
            </div>

        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-18.jpg" alt=""
                    class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-15.jpg" alt=""
                    class="img-fluid" />
            </div>

        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-19.jpg" alt=""
                    class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-20.jpg" alt=""
                    class="img-fluid" />
            </div>

        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-21.jpg" alt=""
                    class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-24.jpg" alt=""
                    class="img-fluid" />
            </div>

        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-22.jpg" alt=""
                    class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-23.jpg" alt=""
                    class="img-fluid" />
            </div>

        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-5.jpg" alt=""
                    class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-11.jpg" alt=""
                    class="img-fluid" />
            </div>

        </div>

        <div class="row">
            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-12.jpg" alt=""
                    class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/works/refugio-das-oliveiras/refugio-das-oliveiras-14.jpg" alt=""
                    class="img-fluid" />
            </div>

        </div>


    </div>
</div>