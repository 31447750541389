import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BioComponent } from './pages/bio/bio.component';


import { TrabalhoComponent } from './pages/trabalho/trabalho.component';
import { ContactsComponent } from './pages/contacts/contacts.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { WorkDetailComponent } from './pages/work-detail/work-detail.component';
import { DroneComponent } from './pages/drone/drone.component';
import { WorldmapComponent } from './pages/worldmap/worldmap.component';
import { MadeiraComponent } from './pages/travels-pages/madeira/madeira.component';
import { MoroccoComponent } from './pages/travels-pages/morocco/morocco.component';
import { PicosComponent } from './pages/travels-pages/picos/picos.component';
import { TravelsComponent } from './pages/travels/travels.component';
import { JordanComponent } from './pages/travels-pages/jordan/jordan.component';
import { LinksComponent } from './pages/links/links.component';
import { FpvComponent } from './pages/fpv/fpv.component';
import { InterrailComponent } from './pages/travels-pages/interrail/interrail.component';
import { PrintsComponent } from './pages/prints/prints.component';
import { NorwayComponent } from './pages/travels-pages/norway/norway.component';
import { PhotoComponent } from './pages/photo/photo.component';
import { TimelinePageComponent } from './timeline-page/timeline-page.component';
import { AlpsComponent } from './pages/travels-pages/alps/alps.component';
import { AccommodationPricesComponent } from './pages/prices/accommodation-prices/accommodation-prices.component';
import { WeddingsComponent } from './pages/weddings/weddings.component';
import { AcoresComponent } from './pages/travels-pages/acores/acores.component';
import { CafeCentralComponent } from './pages/cafe-central/cafe-central.component';


const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'links', component: LinksComponent },
  { path: 'about', component: BioComponent },
  { path: 'drone', component: DroneComponent },
  { path: 'fpv', component: FpvComponent },
  { path: 'contacts', component: ContactsComponent },
  { path: 'work', component:  TrabalhoComponent},
  { path: 'work/type/:type', component:  TrabalhoComponent},
  { path: 'work/:name', component: WorkDetailComponent },
  { path: 'travel', component: TravelsComponent },
  { path: '2022/picos', component: PicosComponent },
  { path: '2022/morocco', component: MoroccoComponent },
  { path: '2023/jordan', component: JordanComponent },
  { path: '2022/madeira', component: MadeiraComponent },
  { path: '2023/interrail', component: InterrailComponent },
  { path: '2023/norway', component: NorwayComponent },
  { path: 'worldmap', component: WorldmapComponent },
  { path: 'prints', component: PrintsComponent },
  { path: 'photo', component: PhotoComponent },
  { path: '2023/alps', component: AlpsComponent },
  { path: 'timeline', component: TimelinePageComponent },
  { path: 'weddings', component: WeddingsComponent },
  { path: '2024/acores', component: AcoresComponent },
  { path: 'prices/accommodation', component: AccommodationPricesComponent },
  { path: 'cafe-central', component: CafeCentralComponent },
  { path:'',redirectTo:'/', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
