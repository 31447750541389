import { Component, OnInit } from '@angular/core';
import { WorksService } from 'src/app/services/works.service';
import AOS from "aos";


@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.css']
})
export class HomepageComponent implements OnInit {

  constructor(private worksService : WorksService) { }


  images: string[] = [
    'assets/img/travels/2023/alps/alps-25.jpg',
    'assets/img/travels/2023/norway/norway-23.jpg',
    'assets/img/travels/2023/interrail/bled-3.jpg',
    'assets/img/travels/2022/picos/picos-16.jpg',
    'assets/img/travels/2024/picos/picos-14.jpg'
  ];

  currentImage: string = this.images[0];
  private currentIndex: number = 0;
  private intervalId: any;

  ngOnInit(): void {
    this.startCarousel();
    AOS.init();
  }

  startCarousel(): void {

    this.shuffleImages();
    this.intervalId = setInterval(() => {
      this.currentIndex = (this.currentIndex + 1) % this.images.length;
      this.currentImage = this.images[this.currentIndex];
    }, 5000); // Troca a cada 10 segundos
  }

  shuffleImages(): void {
    for (let i = this.images.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [this.images[i], this.images[j]] = [this.images[j], this.images[i]];
    }
  }

  travels =[ 
    {
      url: "work/type/outdoor",
      img: "assets/img/works/gravel-birds-2024/thumb.jpg",
      name: "Outdoor & Lifestyle",
      show: true,
      col: 12,
    },
    {
      url: "weddings",
      img: "assets/img/weddings/thumb1.jpeg",
      name: "HOME_WEDDINGS",
      show: true,
      col: 12,
    },
    {
      url: "work/type/corporate",
      img: "assets/img/works/red-luz/thumb.jpg",
      name: "HOME_CORPORATE",
      show: true,
      col: 12,
    },
    {
      url: "/travel",
      img: "assets/img/travels/2022/madeira/madeira-5.jpg",
      name: "HOME_TRAVEL",
      show: true,
      col: 12,
    },
  ]

}
