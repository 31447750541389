import { Component, OnInit } from '@angular/core';
import AOS from "aos";


@Component({
  selector: 'app-acores',
  templateUrl: './acores.component.html',
  styleUrls: ['./acores.component.css']
})
export class AcoresComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    AOS.init();
  }

}
