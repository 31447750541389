<div class="container mt-5" data-aos="fade-up">


    <div class="row justify-content-center">

        <div class="col-sm-8 text-center">

            <h1 class="dillan mb-2">Café Central</h1>

            <p class="p-5">
                Café Central. Quantos de nós já nos cruzámos com esta designação à porta de um estabelecimento comercial? Pois bem, atendendo ao facto de haver um ‘Café Central’ em cada canto, decidimos avançar para a produção de um documentário. O conceito é relativamente simples: 10 cafés em vários pontos do país, três dias em cada um. Queremos conhecer a rotina destes espaços, as pessoas que os compõem e as histórias que têm para contar. Haverá melhor forma de experienciar Portugal?
            </p>
        </div>
    </div>

    <div class="adventures">

        <div class="row justify-content-center">

            <div class="col-sm-9 text-center">

                <div class="ratio ratio-16x9 mb-3">
                    <iframe class="border-radius" [src]="'https://www.youtube.com/embed/eTferNhEJEU' | safe"
                        title="YouTube video" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>

</div>