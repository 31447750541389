

<div class="container text-center mt-5">

    <div class="row justify-content-center text-center">
        <div class="col-sm-8 mb-5">
            <h1 class="dillan">Alpes</h1>
            <p><i>Dec 2023</i></p>
            <hr class="py-2"/>

            <p>
                Esta viagem marcou-me por dois motivos. Primeiro foi a primeira viagem após despedir-me como Eng Informático e iniciar uma carreira a full time como freelancer na área do vídeo. Saí da empresa onde estava a 28 de Novembro e esta viagem comecou dia 2 de Dezembro. O segundo motivo foi o facto de ter sido a minha primeira viagem numa autocaravana onde, sem dúvida, o meu gosto pela vanlife intensificou-se ainda mais.
            </p>
            <p>
                Começamos em Milão, passamos pelo Lago Di Como e seguimos para o destino principal: Dolomites. Passamos praticamente uma semana a explorar os sítios mais icónicos deste Parque Natural e seguimos para a norte, mais concretamente em direção à Áustria. Passamos na Suiça, onde tive a oportunidade de reviver as minhas origens e comer uma bela Raclette em casa dos meus tios. Após a Suiça, passamos por frança e visitamos a vila de Chamonix. Regressamos pelo Sul da Suiça e acabamos a viagem com começamos: Milão.
            </p>

            <p>
                A zona dos Alpes é um lugar que estava na minha lista há algum tempo e poder visita-los pela primeira vez numa autocaravana e cobertos de neve foi delicioso.
            </p>

        </div>
    </div>

</div>


<div class="container">

    <div class="travel" data-aos="fade-up" data-aos-duration="1000">

       
        <div class="row vertical-photos">
            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-3.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-18.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
        

            <div class="col-lg-12">
                <img src="assets/img/travels/2023/alps/alps-2.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row vertical-photos">
          
            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-52.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-4.jpg" alt="" class="img-fluid" />
            </div>

        </div>

      

        <div class="row">

            <div class="col-lg-7">
                <img src="assets/img/travels/2023/alps/alps-7.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-5">
                <img src="assets/img/travels/2023/alps/alps-8.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>

        <div class="row">
            <div class="col-lg-3">
                <img src="assets/img/travels/2023/alps/alps-10.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-5">
                <img src="assets/img/travels/2023/alps/alps-11.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-9.jpg" alt="" class="img-fluid" />
            </div>
        
        </div>

        <div class="row">

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-12.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-13.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>

        <div class="row">

            <div class="col-lg-3">
                <img src="assets/img/travels/2023/alps/alps-17.jpg" alt="" class="img-fluid" />
            </div>


            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-15.jpg" alt="" class="img-fluid" />
            </div>

            
            <div class="col-lg-3">
                <img src="assets/img/travels/2023/alps/alps-16.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>

        <div class="row">

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-19.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-20.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>

        <div class="row vertical-photos">

            <div class="col-lg-12">
                <img src="assets/img/travels/2023/alps/alps-21.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>

        <div class="row">

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-22.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-24.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-23.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>

        <div class="row">

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-25.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-29.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>


        <div class="row">

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-27.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-26.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-28.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>

        <div class="row">

            <div class="col-lg-3">
                <img src="assets/img/travels/2023/alps/alps-31.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-9">
                <img src="assets/img/travels/2023/alps/alps-32.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>



        <div class="row">

            <div class="col-lg-12">
                <img src="assets/img/travels/2023/alps/alps-33.jpg" alt="" class="img-fluid" />
            </div>

           
        </div>


        <div class="row">

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-34.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-35.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-37.jpg" alt="" class="img-fluid" />
            </div>
           
        </div>

        <div class="row">

            <div class="col-lg-5">
                <img src="assets/img/travels/2023/alps/alps-36.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-7">
                <img src="assets/img/travels/2023/alps/alps-38.jpg" alt="" class="img-fluid" />
            </div>
      
        </div>

        <div class="row">

            <div class="col-lg-8">
                <img src="assets/img/travels/2023/alps/alps-40.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-39.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row">

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-41.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-8">
                <img src="assets/img/travels/2023/alps/alps-43.jpg" alt="" class="img-fluid" />
            </div>

        </div>


        <div class="row">

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-45.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-44.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row">

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-47.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-50.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2023/alps/alps-48.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row">

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-49.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/travels/2023/alps/alps-51.jpg" alt="" class="img-fluid" />
            </div>

        </div>


    </div>

</div>