<div class="container text-center mt-5">
    <div class="row justify-content-center text-center">
        <div class="col-sm-8 mb-5">
            <h1 class="dillan">Marrocos</h1>
            <p><i>Dec 2022</i></p>
            <hr class="py-2"/>
            <p>Fui convidado pelo Miguel Nunes <b><a
                        href="https://www.instagram.com/miguelnunesdp/" target="_blank">@miguelnunesdp</a></b> para ir com ele e
                juntamente com mais malta para realizar conteúdos proomocionais para a empresa de turismo de Marrocos, a <b><a
                    href="https://www.instagram.com/miguelnunesdp/" target="_blank">@trips_to_morocco</a></b>.
                O meu papel foi fotografar, tanto a cidade de Marrakesh e também as tendas e o deserto em Merzouga, onde
                essa empresa tinha um alojamento fascinante.
            </p>
            <p>Passamos cerca de dois dias em Marrakesh e fomos em direção ao deserto em Merzouga. Pelo meio fomos
                fazendo várias paragens, sendo elas em Aït Benhaddou, Ouarzazate e Thingir. Os dias no deserto,
                contrastando com Marrakesh, foram de uma calma imensa onde tivemos oportunidade de visitar uma família
                nómada, explorar locais completamente inóspitos e andar de moto 4 ao pôr do sol no meio das dunas. </p>
        </div>
    </div>

</div>

<app-timeline [cities]="cities"></app-timeline>


<div class="container">


    <div class="travel" data-aos="fade-up" data-aos-duration="1000">

        <!-- 
        <div class="text-center pb-3">

            <div class="d-inline ">
                <span class="badge bg-dark text-center">2023</span>
            </div>
            <div class="d-inline flag">
                <img src="https://raw.githubusercontent.com/catamphetamine/country-flag-icons/master/flags/3x2/MA.svg" />
            </div>
            <div class="d-inline flag">
                <img src="https://raw.githubusercontent.com/catamphetamine/country-flag-icons/master/flags/3x2/DE.svg" />
            </div>
            <div class="d-inline flag">
                <img src="https://raw.githubusercontent.com/catamphetamine/country-flag-icons/master/flags/3x2/AT.svg" />
            </div>
            <div class="d-inline flag">
                <img src="https://raw.githubusercontent.com/catamphetamine/country-flag-icons/master/flags/3x2/SI.svg" />
            </div>
            <div class="d-inline flag">
                <img src="https://raw.githubusercontent.com/catamphetamine/country-flag-icons/master/flags/3x2/HR.svg" />
            </div>
            <div class="d-inline flag">
                <img src="https://raw.githubusercontent.com/catamphetamine/country-flag-icons/master/flags/3x2/BA.svg" />
            </div>
            <div class="d-inline flag">
                <img src="https://raw.githubusercontent.com/catamphetamine/country-flag-icons/master/flags/3x2/ME.svg" />
            </div>
            <div class="d-inline flag">
                <img src="https://raw.githubusercontent.com/catamphetamine/country-flag-icons/master/flags/3x2/AL.svg" />
            </div>
           
            <h1 class="text-center mt-3">Uma viagem inesperada <br /> por marrocos</h1>
            <h1 class="text-center mt-3">Marrocos</h1>

        </div>

    -->



        <div class="row justify-content-center">
            <div class="col-sm-10 mb-5">
                <div class="ratio ratio-16x9 mb-3 mb-5">
                    <iframe class="border-radius" [src]="'https://www.youtube.com/embed/Zb-eIkQv-RQ' | safe"
                        title="YouTube video" allowfullscreen></iframe>
                </div>
            </div>
        </div>

        <div class="row justify-content-center">
            <div class="col-sm-10 mb-5">
                <div class="ratio ratio-16x9 mb-3 mb-5">
                    <iframe class="border-radius" [src]="'https://www.youtube.com/embed/Tmzt2SHo-Ec' | safe"
                        title="YouTube video" allowfullscreen></iframe>
                </div>
            </div>
        </div>

        <div class="row d-flex justify-content-center">

            <!--         
            <div class="col-8 ">
                <p class="my-3 text-color">
                    Dizem que as melhores coisas que acontecem são quando são inesperadas e nesta viagem foi exatamente
                    isso que aconteceu. Marrocos é um destino que não estava de todo na minha bucket list, mas algo que
                    me tenho apercebido nos últimos tempos é que nem sempre temos de ter tudo planeado. Tem acontecido
                    em várias viagens, e sinceramente estou adorar. Fui convidado pelo Miguel Nunes, ele arranjou uma
                    parceria com uma empresa de tours de Marrocos e estava à procura de um fotógrafo para integrar na
                    equipa. Apesar da fotografia não ser o meu foco, mas sim vídeo, não consegui dizer que não e lá
                    embarquei nesta aventura. Juntamente com o Miguel, a equipa era composta por mais 3 jovens
                    raparigas, a Sofia, Cátia e Cristina.

                    <br />
                    <br />
                    O nosso voo saiu de Lisboa às 22h00 e aterrámos em Marrakesh por volta da 00h00. Fiquei logo
                    admirado com a arquitetura do aeroporto, mal saí do avião. Uma estrutura em tons claros com um
                    padrão moderno. Era oficial: estava pela primeira vez fora da Europa.
                    A chegada à parte interior do aeroporto foi tranquila, apenas tivemos que preencher um papel com
                    informações básicas da viagem. O nosso guia estava à porta do aeroporto. Um homem novo, super
                    simpático , educado e sereno. Chamava-se Moahmaed (claro!). Percebemos logo que iria ser um ótima
                    companhia nos próximos 8 dias. Chegamos ao jipe, e colocamos as nossas malas em cima do mesmo, onde
                    apenas uma rede as segurava. Estranho? Talvez, mas pelos visto lá era normal. Afinal de contas
                    éramos 6 ao todo, e nao havia espaços para as malas. Durante a viagem fomos quebrando o gelo,
                    trocando algumas palavras com o nosso guia.
                    <br />
                    <br />
                    Chegar a uma capital europeia de noite nem sempre é muito agradável, ainda para mais num país tão
                    diferente como Marrocos. (pelo menos áquilo que estava habituado). O nosso guia entrou numa rua da
                    Medina apertada e estacionou o carro. Para contextualizar, a Medina em Marrakesh é uma cidade
                    histórica murada, localizada no centro. É uma das atrações turísticas mais populares da cidade e é
                    conhecida por ter ruas estreitas, labirínticas, com muitos mercados, lojas, restaurantes e outros
                    negócios, além de inúmeras mesquitas, palácios e monumentos históricos.
                </p>
            </div>

            -->


        </div>


        <div class="row vertical-photos">

            <div class="col-lg-6">
                <img src="assets/img/travels/2022/morocco/morocco13.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/travels/2022/morocco/morocco24.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <!--
        <p class="my-3 text-color">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like
            Aldus PageMaker including versions of Lorem Ipsum.
        </p>
         -->

        <div class="row">

            <div class="col-lg-12">
                <img src="assets/img/travels/2022/morocco/morocco10.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row vertical-photos">

            <div class="col-lg-5">
                <img src="assets/img/travels/2022/morocco/morocco20.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-7">
                <img src="assets/img/travels/2022/morocco/morocco8.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row">
            <div class="col-6">
                <img src="assets/img/travels/2022/morocco/morocco19.jpg" alt="" class="img-fluid" />
            </div>


            <div class="col-6">
                <img src="assets/img/travels/2022/morocco/morocco-26.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-12">
                <img src="assets/img/travels/2022/morocco/morocco21.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <div class="row">
            <div class="col-lg-7">
                <img src="assets/img/travels/2022/morocco/morocco18.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-5">
                <img src="assets/img/travels/2022/morocco/morocco16.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <!--         
        <p class="my-3 text-color">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like
            Aldus PageMaker including versions of Lorem Ipsum.
        </p>
        -->

        <div class="row vertical-photos">

            <div class="col-lg-5">
                <img src="assets/img/travels/2022/morocco/morocco6.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-7">
                <img src="assets/img/travels/2022/morocco/morocco22.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <div class="row">
            <div class="col-12">
                <img src="assets/img/travels/2022/morocco/morocco2.jpg" alt="" class="img-fluid" />
            </div>
        </div>

        <!--         
        <p class="my-3 text-color">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like
            Aldus PageMaker including versions of Lorem Ipsum.
        </p>
        -->

        <div class="row vertical-photos">

            <div class="col-lg-6">
                <img src="assets/img/travels/2022/morocco/morocco3.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-6">
                <img src="assets/img/travels/2022/morocco/morocco1.jpg" alt="" class="img-fluid" />
            </div>

        </div>

        <!--         
        <p class="my-3 text-color">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like
            Aldus PageMaker including versions of Lorem Ipsum.
        </p>
        -->

        <div class="row">

            <div class="col-lg-8">
                <img src="assets/img/travels/2022/morocco/morocco12.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-4">
                <img src="assets/img/travels/2022/morocco/morocco25.jpg" alt="" class="img-fluid" />
            </div>

        </div>
        <div class="row">
            <div class="col-lg-5">
                <img src="assets/img/travels/2022/morocco/morocco4.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-7">
                <img src="assets/img/travels/2022/morocco/morocco14.jpg" alt="" class="img-fluid" />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <img src="assets/img/travels/2022/morocco/morocco-27.jpg" alt="" class="img-fluid" />
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <img src="assets/img/travels/2022/morocco/morocco23.jpg" alt="" class="img-fluid" />
            </div>
        </div>
        <!--         
        <p class="my-3 text-color">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and
            scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into
            electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like
            Aldus PageMaker including versions of Lorem Ipsum.
        </p>
        -->
        <div class="row vertical-photos">
            <div class="col-lg-6">
                <img src="assets/img/travels/2022/morocco/morocco5.jpg" alt="" class="img-fluid" />
            </div>
            <div class="col-lg-6">
                <img src="assets/img/travels/2022/morocco/morocco7.jpg" alt="" class="img-fluid" />
            </div>
        </div>


        <h3 class="dillan text-center m-5">
            A equipa
        </h3>

        <div class="row profile justify-content-center my-5">
            <div class="col-lg-2 text-center">
                <a target="_blank" href="https://www.instagram.com/nelsonrsousa/">
                    <img src="assets/img/travels/2022/morocco/team/nelson.jpg" alt="" class="img-fluid" />
                </a>
            </div>

            <div class="col-lg-2 text-center">
                <a target="_blank" href="https://www.instagram.com/soofcunha/">
                    <img src="assets/img/travels/2022/morocco/team/sofia.jpg" alt="" class="img-fluid" />
                </a>
            </div>

            <div class="col-lg-2 text-center">
                <img src="assets/img/travels/2022/morocco/team/cristina.jpg" alt="" class="img-fluid" />
            </div>

            <div class="col-lg-2 text-center">
                <a target="_blank" href="https://www.instagram.com/miguelnunesdp/">
                    <img src="assets/img/travels/2022/morocco/team/miguel.jpg" alt="" class="img-fluid" />
                </a>
            </div>

            <div class="col-lg-2 text-center">
                <a target="_blank" href="https://www.instagram.com/catiaferreiraa/">
                    <img src="assets/img/travels/2022/morocco/team/catia.jpg" alt="" class="img-fluid" />
                </a>
            </div>
        </div>
    </div>
</div>