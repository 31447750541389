import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import AOS from "aos";
import { WORK_TYPES, WorksService } from 'src/app/services/works.service';

@Component({
  selector: 'app-trabalho',
  templateUrl: './trabalho.component.html',
  styleUrls: ['./trabalho.component.css']
})
export class TrabalhoComponent implements OnInit {

  constructor(private worksService: WorksService, private activatedRoute: ActivatedRoute) { }

  selectedType = "";

  works = [];

  types = [
    WORK_TYPES.OUTDOOR,
    WORK_TYPES.ACCOMMODATION, 
    WORK_TYPES.CORPORATE, 
    WORK_TYPES.EVENTS, 
    WORK_TYPES.MUSIC
  ]

  ngOnInit(): void {
    AOS.init();

    this.selectedType = this.activatedRoute.snapshot.paramMap.get('type');


    if(this.selectedType) {
      this.works = this.worksService.getByType(this.selectedType);

    } else {
      this.works = this.worksService.getAll();

    }


    console.log(this.works);
  }

}
