import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import AOS from "aos";

@Component({
  selector: 'app-cafe-central',
  templateUrl: './cafe-central.component.html',
  styleUrls: ['./cafe-central.component.css']
})
export class CafeCentralComponent implements OnInit {

  constructor(private meta: Meta) { }

  ngOnInit(): void {
    AOS.init();

    this.meta.updateTag({ property: 'og:title', content: 'Nelson Sousa | Café Central' });
    this.meta.updateTag({ property: 'og:description', content: 'Quantos de nós já nos cruzámos com esta designação à porta de um estabelecimento comercial?' });
    this.meta.updateTag({ property: 'og:image', content: 'assets/img/seo/cafe-central.jpeg' });
  }


 
}
